import React from "react";

const PrivacyPolicy = () => {
  return (
    <div
      className="Lato w-[1200px] mx-auto  flex flex-col gap-12 py-24 justify-center items-center"
      id="privacy"
    >
      <h1 className="text-[#101828] text-[40px] font-bold">Privacy Policy</h1>
      <p className="text-[#0F172A] text-2xl leading-9 ">
        Flashport Technologies Private Limited, which is a company duly
        incorporated under the laws of India, and its affiliates worldwide
        (hereinafter collectively referred to as the “Company”, “Flash” “we”,
        “us”, “our”) provide buyer and market access, order fulfillment,
        shipping, global trade compliance, and related financial transactions.
      </p>

      <div className="flex flex-col gap-8 text-[#0F172A]">
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            1. Applicability and  Acceptance
          </h3>
          <p className="text-2xl leading-9">
            <strong>1.1</strong> By signing up for any of the services or
            products offered by Flash (collectively, the “Services”), accessing
            our website at www.goflash.in or mobile sites/application
            (“Platform”), by browsing or accessing the Platform, using any one
            or more Services, communicating with us or the Platform, providing
            information on or through the Platform, the individual (“User”,
            “you”, “your”) acknowledges that the User has read and understood
            this Privacy Policy (“Policy”) and the information collection and
            handling practices outlined in it and the User expressly consents to
            the terms of this Policy. <br /> <strong>1.2</strong> This Policy is
            applicable to the Users who provide their Personal Information (as
            defined below) to the Company either through the Platform or
            otherwise, or whose Personal Information the Company otherwise
            collects, receives or processes in connection with the offer and
            sale of its Services. However, this Policy does not apply to
            Personal Information collected from you offline (unless otherwise
            specified) or to third-party websites to which Platform may link.
            This Policy should be read in conjunction and together with the
            terms of services and other policies as may be available on the
            Platform (collectively the “Terms of Service”). Please read this
            Policy carefully and in the event, you do not agree to the terms or
            policies specified herein, please discontinue the usage of the
            Platform and the Services. <br /> <strong>1.3</strong> If you are a
            current or former employee of Flash, or a job applicant, Employee
            Privacy Policy describes the Personal Information that Flash
            collects from or about you, and how we use and to whom we disclose
            such Personal Information, from the point at which you make an
            application to work with us (e.g. in your application) and
            throughout your employment with us (e.g., through on-boarding, using
            our systems, and signing up for benefits).
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">2. Personal Information</h3>
          <p className="text-2xl leading-9">
            <strong>2.1</strong> While using our Services, we may ask you to
            provide us with certain personally identifiable information
            (“Personal Information”) that can be used to contact or identify
            you. The use and the collection of the Personal Information by the
            Company is dependent upon how the User interacts with the Platform
            and/or avails Our Services. Such Personal Information may include,
            but is not limited to:(a) First name and Last name;(b) Email
            address;(c) Phone number;(d) Date of Birth;(e) Address, State,
            Province, ZIP/Postal code, city;(f) Tax registration numbers;(g)
            Identification numbers;(h) Applicable KYC Documents;(i) Geographical
            Location. <br />
            <strong>2.2</strong> We collect the above Personal Information when
            you sign up for our Services, access our Services, and at any time
            you update your Personal Information on your Flash account.
            <br /> <strong>2.3</strong> ‍ It is your voluntary decision as to
            whether or not to provide the information as requested on the
            Platform. However, if you do not provide such information and/or
            data, we may not be able to provide certain Services or certain
            features of our Services or Platform, and your use of the Platform
            and our Services would be limited as a result. ‍<br />
            <strong>2.4</strong> We may also receive your Personal Information
            from third parties, such as our partners, third party service
            providers, etc. This may include your first and last name,
            identification number, age, gender, email address, address, delivery
            address and any information that you or the third-party service
            provider may share with the Company. <br /> ‍<strong>2.5</strong>{" "}
            You may provide payment information when you use the Platform to
            avail our Services, including credit cards numbers, billing
            information using third-party intermediary PCI-DSS compliant service
            providers. The payment information is provided directly by you, via
            the Platform, into the PCI/DSS-compliant payment processing service
            to which the Company subscribes, and the Company does not, itself,
            process or store the payment information, except as stated herein.
            Further, these intermediaries are not permitted to store, retain, or
            use your billing information for any purpose except for payment
            processing on our behalf. ‍<br />
            <strong>2.6</strong> We may automatically collect behavioral and
            usage information about your visits to the Platform including the
            pages you view, the links and advertisements you click, search terms
            you enter, and other actions you take in connection with the
            Platform and Services. We may also collect certain information from
            the browser you use to come to Platform, such as your IP address,
            device identifier, location data browser type and language, access
            times, the Uniform Resource Locator (URL) of the website that
            referred you to our Platform and the URL to which you browse away
            from our site if you click on a link on our site.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">3. Purpose</h3>
          <p className="text-2xl leading-9">
            <strong>3.1</strong> Our purposes for collection of Personal
            Information shall include without limitation to the following:(a) to
            verify your identity;(b) to provide Users with Services,
            information, marketing and promotional materials;(c) for the
            management of the Services (such as parcel transportation,
            warehousing and technology services) provided to the Users;(d) to
            handle, respond and follow up service calls, enquiries, queries,
            feedback, requests and complaints;(e) to contact Users regarding our
            Services and provide updates;(f) to understand, diagnose,
            troubleshoot and fix issues with the Platform and the Services;(g)
            to communicate with you about your account, Services and activities
            on the Platform;(h) to conduct polls and surveys and periodically
            reach out to you via phone, email, or chat to understand your
            experience in using our Services;(i) to compile and analyze
            aggregate statistics in respect of the use of the Platform, Services
            and for our internal use;(j) to evaluate and develop new features,
            technologies and improvements for providing the Services;(k) ask for
            ratings and reviews of the Services and the Platform; and(l) to
            comply with legal and regulatory regulations, including customs and
            GST requirements.
            <br /> <strong>3.2</strong> Flash strives to collect only such
            Personal Information which is necessary and adequate but not
            excessive in relation to the purposes set out herein-above. To
            protect your privacy, you should not provide the Company with any
            information that is not specifically requested. In the event that
            Flash requires the use of your Personal Information for a purpose
            other than those set out herein, Flash shall process the Personal
            Information, so long as we have a ground under the law to do so
            which may be based on one of the following conditions:(a)
            Performance of a contract: Herein, we process the Personal
            Information as it is necessary in order to fulfil our obligations
            set forth under a contract or for the performance of our agreement
            with you;(b) Legal obligation: Herein, we process the Personal
            Information in order to comply with a legal obligation, such as
            keeping records for tax purposes or providing information to a
            public body or law enforcement agency;(c) Legitimate interests:
            Herein, we process your Personal Information where it is in our
            legitimate interest in running a lawful business. We will only rely
            on such a ground where an assessment has been performed balancing
            the interests and rights involved and the necessity of the
            processing in order to provide our Services, Platform and features
            to you. Few examples of Our legitimate interests are: (i) to offer
            information and/or services to individuals who visit the Platform or
            (ii) to prevent fraud or criminal activity and to safeguard our IT
            systems etc.
            <br /> <strong>3.3</strong> We will not collect additional
            categories of Personal Information or use the Personal Information
            we collected for materially different, unrelated, or incompatible
            purposes without seeking your specific permission to process your
            Personal Information and we will only process your Personal
            Information in this way if you agree to us doing so.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            4. Sharing Personal Information
          </h3>
          <p className="text-2xl leading-9">
            <strong>4.1</strong> We do not rent, share, list or sell the
            Personal Information with any third parties, except as necessary for
            our legitimate professional and business needs, to allow you to
            access the Platform, provide Services, carry out your requests,
            and/or as required or permitted by an applicable law. <br />
            <strong>4.2</strong> Flash works with third party services providers
            to develop, improve and maintain the Platform and provide the
            Services and we may share Personal Information with them for
            fulfillment of our Services. Examples of third-party service
            providers are our listed couriers, shipping companies, insurance
            companies, payment providers and customer communication tools. In
            all instances, only necessary Personal Information fields will be
            shared. <br /> <strong>4.3</strong> We use third-party analytics
            services to evaluate your use of the Platform, compile reports on
            activity, collect demographic data, analyze performance metrics, and
            collect and evaluate other information relating to the Platform and
            mobile and internet usage. These third parties use cookies and other
            technologies to help analyze and provide us the information and, in
            some cases, connect such information with other data held by our, or
            third party, environments. You consent to the processing of
            information about you by these analytics providers in the manner and
            for the purposes set out in this Policy. <br /> <strong>4.4</strong>{" "}
            The Company may also make all Personal Information accessible to its
            employees and data processors/third party vendors only on a
            need-to-know basis and for the purposes set out in this Policy. The
            Company takes adequate steps to ensure that all the employees and
            data processors/third party vendors, who have access to, and are
            associated with the processing of Personal Information, respect its
            confidentiality and that such data processors/third party vendors
            adopt at least such reasonable level of security practices and
            procedures as required under applicable law. <br />{" "}
            <strong>4.5</strong> To the extent permissible under the applicable
            law, we may use and disclose any non-personal data (data which is
            does not contain any information that can be used to identify
            natural person) for any purpose. However, in the event we combine
            any non-personal data with Personal Information, then we will only
            use and disclose such combined information for the purposes
            described above while it is so combined. <br /> <strong>4.6</strong>{" "}
            We may also disclose or transfer the Personal Information, to
            another third party as part of reorganization or a sale of the
            assets or business of the Company. Any third party to which the
            Company transfers or sells its assets will have the right to
            continue to use the Personal Information and/or other information
            that a User provides to us in a manner consistent with this Policy.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">5. Transfer Of Data</h3>
          <p className="text-2xl leading-9">
            Your information, including Personal Information, may be transferred
            to and maintained on computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those from your jurisdiction. If you
            are located outside India and choose to provide information to us,
            please note that we transfer the data, including Personal
            Information, to India and process it there. Your consent to this
            Policy followed by your submission of such information represents
            your agreement to that transfer. Flash will take all steps
            reasonably necessary to ensure that your data is treated securely
            and in accordance with this Policy and no transfer of your Personal
            Information will take place to an organisation or a country unless
            there are adequate controls in place including the security of your
            data and other Personal Information.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            6. Links to third party websites
          </h3>
          <p className="text-2xl leading-9">
            We may display links to websites that are owned or operated by third
            parties on our Platform. Please understand that these websites are
            independent from the Company, and the Company has no control over
            the content on that website, even if the Company provides
            information or Services to the owner of that website. The Company is
            not making any representations about, endorsing, or accepting any
            responsibility for the content or the use of such website. Further,
            we are not responsible or liable for any damage or loss related to
            the use of any third-party website. You should always read the terms
            and conditions and the privacy policy of a third-party website
            before accessing or using it.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            7. Security Practices and Procedures
          </h3>
          <p className="text-2xl leading-9">
            <strong>7.1</strong> We take information security very seriously and
            maintain reasonable administrative, technical and physical
            safeguards designed to protect the Personal Information you provide
            against accidental, unlawful or unauthorized destruction, loss,
            alteration, access, disclosure or use.
            <br /> <strong>7.2</strong> We use reasonable security measures to
            help protect against the loss, misuse and alteration of the Personal
            Information under our control. However, despite our best efforts,
            security cannot be absolutely guaranteed against all threats. In
            addition, please note that emails, messages sent via your web
            browser, and other similar means of communication with other Users,
            are not encrypted. Therefore, while we strive to protect your
            Personal Information, we cannot guarantee its security. <br />‍
            <strong>7.3</strong> To the best of our ability, the access to your
            Personal Information is limited to those who have a need to know.
            Those individuals who have access to the Personal Information are
            required to maintain the confidentiality of such data. In addition,
            please note that emails, messages sent via your web browser, and
            other similar means of communication with other users, are not
            encrypted. Therefore, while we strive to protect your information,
            we cannot guarantee its security.
            <br /> <strong>7.4</strong> Please also be aware that we may use
            third-party cloud service providers that provide hosting, data
            storage and other services pursuant to standard terms and conditions
            that may be non-negotiable. These service providers have informed us
            or the general public that they apply security measures they
            consider adequate for the protection of information within their
            system, or they have a general reputation for applying such
            measures. However, we will not be liable (to the fullest extent
            permitted by law) for any damages that may result from the misuse of
            any information, including Personal Information, by these companies.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">8. Use of Cookies</h3>
          <p className="text-2xl leading-9">
            The Platform uses “cookies” to monitor certain aspects of how our
            Users interact with the Platform. Cookies are small text files
            placed on your computer when you visit the Platform, and which allow
            us to recognize you and allow us to keep a record of your activities
            on the Platform making your subsequent visits to the Platform more
            efficient. We use cookies to (1) enable you to navigate our
            web-based products, applications, and Services and use certain
            features, (2) collect information about our web-based applications,
            and Services so that we can analyze your and others’ usage and make
            necessary or appropriate updates / improvements, and (3) enhance or
            personalize our web-based applications, and Services for you. By
            continuing the use of the Platform, you are agreeing to our use of
            cookies. If you do not agree to our use of cookie, you can block
            them in your browser setting, but you may lose some or all
            functionality on the Platform. However, you will still be able to
            avail the Services. You can also choose to have your computer warn
            you each time a cookie is being sent, through your browser settings.
            You may look at your browser’s help menu to learn the correct way to
            modify your cookies.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">9. Targeted Marketing</h3>
          <p className="text-2xl leading-9">
            Flash also works with advertising and personalization partners to
            use cookies, and internet browsing to help us display personalized
            content and appropriate advertising during your visits to the
            Platform. Cookies placed by these partners also assist us with
            measuring the performance of our advertising campaigns and with
            identifying the pages you view, the links and ads you click on,
            other actions you take on those web pages, and the site from which
            you came before arriving at a web page. These cookies can be
            effective whether you are on Platform or another website. If you opt
            out of receiving targeted display advertising, the data associated
            with these cookies will not be used.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">10. Do-not track statement</h3>
          <p className="text-2xl leading-9">
            Please note that while you may have the opportunity to opt-out of
            targeted advertising through the as stated above and you may be able
            to control the use of cookies through your web browser as described
            in the section above, some web browsers may also give you the
            ability to enable a “do not track” setting. This setting sends a
            special signal to the websites you encounter while web browsing.
            This “do not track” signal is different from disabling certain forms
            of tracking by declining cookies in your browser settings, as
            browsers with the “do not track” setting enabled still have the
            ability to accept cookies. Flash does not respond to web browser “do
            not track” signals at this time. If we do so in the future, we will
            describe how we do so in this Policy.
          </p>
        </div>

        {/* Section 11 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            11. De-Identified Information
          </h3>
          <p className="text-2xl leading-9">
            We may aggregate and/or de-identify any information collected /
            received by us such information can no longer be linked to you or
            your device (“De-Identified Information”). We may use De-Identified
            Information for any purpose, including without limitation for
            research and marketing purposes, and may also share such data with
            any third parties, including advertisers, promotional partners, and
            sponsors, in our discretion.
          </p>
        </div>

        {/* Section 12 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">12. User's Rights</h3>
          <p className="text-2xl leading-9">
            <strong>12.1</strong> In the event, we process your Personal
            Information, you may avail yourself the rights explained below.
            Please note that prior to fulfilling your request in relation to the
            rights provided below, we may verify your identity associated with
            our Platform. In case we are unable to verify your identity, we
            reserve the right to deny a request. If you authorize someone to
            make a request on your behalf, we may also deny your request if we
            are unable to verify with you that the individual making the request
            is authorized to act on your behalf.
          </p>
          <p className="text-2xl leading-9">
            <strong>12.2</strong> In the event, we are processing your Personal
            Information on behalf of another entity or are acting as a data
            processor, we will forward your requests, inquiries or claims
            concerning these processing activities to the respective data
            controller the necessary action.
          </p>
          <p className="text-2xl leading-9">
            <strong>12.3</strong> Your rights are as follows:
            <br />
            (a) <strong>Withdrawal of Consent:</strong> Where we rely on your
            consent in order to process your Personal Information, you have the
            right to withdraw your consent at any time, by writing to us at
            Contact Us.
            <br />
            (b) <strong>
              Right to review, correct, modify and delete:
            </strong>{" "}
            Further, Users can access, review, modify, correct and delete any
            Personal Information provided by them which has been voluntarily
            given by the User and collected by the Company in accordance with
            the Policy.
            <br />
            (c) <strong>Right to opt-out:</strong> The Platform provides an
            option to all the Users to opt-out of receiving any promotional or
            marketing communications from us.
          </p>
        </div>

        {/* Section 13 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            13. Retention of Personal Information
          </h3>
          <p className="text-2xl leading-9">
            <strong>13.1</strong> We will retain your Personal Information only
            as long as it is reasonably required or otherwise permitted or
            required under applicable law or regulatory requirements. <br />
            <strong>13.2</strong> In case a User has withdrawn or cancelled his
            registration on the Platform, we are obliged under law to retain
            Personal Information for a period of one hundred and eighty days
            after such cancellation. In some cases, we may retain only
            non-personally identifiable data indefinitely or to the extent
            allowed by applicable law.
            <br />
            <strong>13.3</strong> Notwithstanding the above, in many situations
            we are required by applicable law to retain all, or a portion, of
            your Personal Information to comply with our legal obligations,
            resolve disputes, enforce our agreements, to protect against
            fraudulent, deceptive, or illegal activity, or for another one of
            our business purposes.
          </p>
        </div>

        {/* Section 14 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">14. Children</h3>
          <p className="text-2xl leading-9">
            Our Platform is not designed for individuals under the age of 18
            (eighteen) (“Child” or “Children”) and we do not knowingly collect
            Personal Information from any Child. If you are a Child, you may
            browse our Platform, but please do not provide your Personal
            Information to us. For example, you cannot register. If we become
            aware that we have inadvertently received Personal Information from
            a visitor who is a Child, we will attempt to delete such Personal
            Information from our records. If you believe that we might have any
            Personal Information of a Child, please inform us by sending an
            email to the email id provided in the “Contact Us” section below.
          </p>
        </div>

        {/* Section 15 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            15. Customer Comments and Content
          </h3>
          <p className="text-2xl leading-9">
            If you post any comments or content on our Platform, you should be
            aware that any information including Personal Information you choose
            to provide there may be read, collected, or used by the third
            parties. We are not responsible for the information you choose to
            submit, and we cannot guarantee that third parties have not made
            copies of or will not use such information in any way
          </p>
        </div>

        {/* Section 16 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">16. Changes to Policy</h3>
          <p className="text-2xl leading-9">
            We may update the Policy from time to time. The revised and most
            current version of the Policy will be updated and posted on the
            Platform directly. You are advised to review this Policy
            periodically for any changes. Changes to the Policy are effective
            when they are posted on the Platform. Any changes to the processing
            of Personal Information as described in this Policy affecting you
            will be communicated to you through an appropriate channel,
            depending on how we normally communicate with you. Your continued
            use of the Platform and Services after we make changes is deemed to
            be acceptance of those changes, so please check the Policy
            periodically for updates.
          </p>
        </div>

        {/* Section 17 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">17. Contact Us</h3>
          <p className="text-2xl leading-9">
            If you have any questions or comments about this Policy, the ways in
            which we collect and use your Personal Information, your choices and
            rights regarding such use, or wish to exercise your rights under
            law, please do not hesitate to contact us at:
            <p className="leading-10">
              Email: support@goflash.in <br /> Postal Address: 346B,SHIV SADAN,
              MANGALAM, VIHAR COLONY, JAGDEO PATH, Patna Aerodrome, Phulwari,
              Patna- 800014, Bihar
              <br /> Attention: Parikshit Bhanushali
            </p>
          </p>
        </div>

        {/* Section 18 */}
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            18. Complaints and Grievance Redressal
          </h3>
          <p className="text-2xl leading-9">
            For Users located in India, any complaints, or concerns with regards
            to content or comment or breach of these terms/ Policy may be
            informed to the designated Grievance Officer as mentioned below in
            writing or through email: Parikshit Bhanushali. Email:
            support@goflash.in, address: Flashport Technologies Private
            Limited,  346B, SHIV SADAN, MANGALAM, VIHAR COLONY, JAGDEO PATH,
            Patna Aerodrome, Phulwari, Patna- 800014, Bihar. [The Grievance
            Officer is identified above pursuant to the provisions of applicable
            laws including but not limited to the Information Technology Act,
            2000 and the Consumer Protection Act, 2019, and the rules enacted
            under those laws.] <br /> For Users located outside India, any
            complaints, or concerns with regards to content or comments or
            breach of these terms/ Policy may be informed to the designated
            Grievance Officer as mentioned below in writing or through email:
            support@goflash.in, address: Flashport Technologies Private Limited,
            346B, SHIV SADAN, MANGALAM, VIHAR COLONY, JAGDEO PATH, Patna
            Aerodrome, Phulwari, Patna- 800014, Bihar.
          </p>
        </div>

        <div className="text-[#0F172A] mt-2">
          <h3 className="text-[32px] font-bold ">Users in US</h3>
          <h4 className="text-2xl font-bold">NOTICE FOR CALIFORNIA USERS</h4>
          <p className="text-2xl leading-9">
            This PRIVACY Notice FOR CALIFORNIA RESIDENTS (“California Notice”)
            supplements the information contained in the general Policy and
            applies solely to visitors, users, and others who reside in the
            State of California. We have adopted this Policy in compliance with
            the California Consumer Privacy Act of 2018 (“CCPA”) and other
            California privacy laws. Any capitalized terms not otherwise defined
            herein shall have the meanings ascribed to them in the CCPA.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            1. Categories of Personal Information collected
          </h3>
          <p className="text-2xl leading-9">
            <strong>1.1</strong> The Personal Information that the Company
            collects, or has collected from the Users in the 12 (twelve) months
            prior to the effective date of this Disclosure, fall into the
            following categories established by the CCPA, depending on which
            Service is used: <br /> (a) Identifiers: such as your name, alias,
            address, phone numbers, IP address, your Flash account log-in
            information, or a government-issued identifier (e.g. social security
            number, which may be required for tax purposes); <br /> (b) Personal
            information categories listed in the California Customer Records
            statute (Cal. Civ. Code § 1798.80(e)): such as a credit card number,
            debit card number or other payment information; <br /> (c) Protected
            Classifications: information that may reveal age, gender, race,
            sexual orientation, or other protected classifications; <br /> (d)
            Commercial information: such as purchase and logistic activity and
            other purchasing or consuming histories or tendencies; <br /> (e)
            Internet or other electronic network activity information: including
            browsing history, search history, information on a consumer's
            interaction with a website, application, or advertisement. and the
            content of email and text messages; <br /> (f) Geolocation data:
            such as the location of your device or computer, which may in some
            cases constitute precise geolocation information; <br /> (g) Audio
            or visual information: such as if a service provider reviews
            recordings of customer service phone calls for quality assurance
            purposes, or if we use a service provider to fulfill your order;{" "}
            <br /> (h) Professional information: for example data you may
            provide about your business; and <br /> (i) Inference data: such as
            information about your activity on the Platform. <br />{" "}
            <strong>1.2</strong> Personal information does not include: <br />{" "}
            (a) Publicly available information from government records. <br />
            (b) De-identified or aggregated consumer information. <br /> (c)
            Information excluded from the CCPA's scope, such as: (i) health or
            medical information covered by the Health Insurance Portability and
            Accountability Act of 1996 (HIPAA) and the California
            Confidentiality of Medical Information Act (CMIA); and (ii) personal
            information covered by certain sector-specific privacy laws,
            including the Fair Credit Reporting Act (FRCA), the
            Gramm-Leach-Bliley Act (GLBA) or California Financial Information
            Privacy Act (FIPA), and the Driver's Privacy Protection Act of 1994.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">
            2. Disclosure of Personal Information
          </h3>
          <p className="text-2xl leading-9">
            We disclose your Personal Information for a business purpose to the
            following categories of third parties: <br /> (a) Our affiliates;{" "}
            <br /> (b) Service providers; and <br /> (c) Third parties to whom
            you or your agents authorize us to disclose your personal
            information in connection with products or services we provide to
            you. For more information on the use and disclosure of personal
            information, please consult our general Policy. In the preceding
            twelve (12) months, we have not sold any Personal Information.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">3. Your Data Rights</h3>
          <p className="text-2xl leading-9">
            <strong>3.1</strong> You may have certain data rights under the
            CCPA. This section describes your rights and explains how to
            exercise those rights. Such rights include: (a) Right to know: You
            have right to request the following information: (i) categories of
            the Personal Information that we have collected from you over the
            past 12 (twelve) months and the specific pieces of Personal
            Information, we have collected from you; (ii) categories of the
            sources from which Personal Information was collected; (iii)
            business or commercial purpose for which the Personal Information
            was collected; (iv) categories of the Personal Information that was
            disclosed for a business purpose or sold during the past 12 (twelve)
            months (v) categories of third parties to whom the Personal
            Information was disclosed or sold; (b) Right to access your Personal
            Information in a portable format; (c) Right to correct or delete
            your Personal Information. However, we might not delete Your
            Personal Information in certain events including (i) to detect
            security incidents; (ii) to debug to identify and repair errors;
            (iii) exercise free speech; (iv) to comply with CaliforniaElectronic
            Communications Privacy Act; (v) to engage in public, peer-reviewed,
            historical or statistical research; (vi) to comply with legal
            obligations; (d) Right to opt out of the sharing of your Personal
            Information for cross-context behavioral advertising, as defined by
            the California Privacy Rights Act; (e) Right to appeal the denial of
            any of these rights by submitting a form that will be provided to
            you if we deny a data request. However, depending on your data
            choices, certain services may be limited or unavailable; and (f)
            Right to non-discrimination You have the right to not be
            discriminated against for exercising Your privacy rights under the
            CCPA. <br />
            <strong>3.2</strong> No sale of personal information. In the 12
            (twelve) months prior to the effective date of this Disclosure,
            Flash has not sold any personal information of consumers, as those
            terms are defined under the California Privacy Rights Act.
            <br /> <strong>3.3</strong>
            California Privacy Rights Act Sensitive Personal Information
            Disclosure. The categories of data that Flash collects and discloses
            for a business purpose include "sensitive personal information" as
            defined under the California Privacy Rights Act. Flash does not use
            or disclose sensitive personal information for any purpose not
            expressly permitted by the California Privacy Rights Act. <br />{" "}
            <strong>3.4</strong>
            California Privacy Rights Act Retention Disclosure. We keep your
            Personal Information to enable your continued use of the Platform
            and the Services, for as long as it is required in order to fulfill
            the relevant purposes described in the Policy, as permitted or as
            may be required by law, or as otherwise communicated to you. For
            example, we retain your transaction history so that you can review
            past purchases (and repeat orders if desired) and what addresses you
            have shipped orders to, and to improve the relevance of products and
            content we recommend. <br />
            <strong>3.5</strong> California Privacy Rights Act
            Non-discrimination Statement. Flash will not discriminate against
            any User for exercising their rights under the CCPA.
            <br /> <strong>3.6</strong> We might request certain additional
            information in order to verify your identity in relation to your
            request for exercising the rights mentioned above. You must also
            specify the rights you wish to exercise. We will verify your request
            and respond to you within 45 (forty-five) days.
            <br /> <strong>3.7</strong> You may designate another person or
            entity (“Authorized Agent”) to act on your behalf in connection with
            Your request for rights that are guaranteed by the CCPA. An
            Authorized Agent is a natural person or business entity registered
            with the Secretary of State to conduct business in California that
            you have authorized to act on Your or to make a request to know or
            to delete. You Authorized Agent can submit a request by sending an
            email to the email id provided in the California Notice attaching
            the signed permission. Please note that we may deny a request from
            an Authorized Agent that does not submit proof that they have
            authorized by you. Under the CCPA, your Authorized Agent is
            responsible for implementing and maintaining reasonable security
            procedures and practices to protect and use your Personal
            Information only for the purpose of fulfilling your reques
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">Employee Privacy Notice</h3>
          <p className="text-2xl leading-9">
            In the course of conducting our business and complying with central/
            federal, state, and local government regulations governing such
            matters as employment, tax, insurance, etc., we must collect
            Personal Information from you. The nature of the information
            collected varies somewhat for each employee, depending on your
            employment responsibilities, your citizenship, the location of the
            facility where you work, and other factors. We collect Personal
            Information from you solely for business purposes, including those
            related directly to your employment with the Company, and those
            required by governmental agencies.
          </p>
        </div>

        <div className="text-[#0F172A]">
          <p className="text-2xl leading-9">
            Data collected may include, without limitation, such things as
          </p>
          <ul className="list-disc text-2xl flex flex-col gap-1">
            <li>Name</li>
            <li>User ID(s)</li>
            <li>Phone numbers</li>
            <li>Email address(es)</li>
            <li>Mailing addresses</li>
            <li>Banking and other financial data</li>
            <li>Family-related data (e.g., marital status)</li>
            <li>Personal and health-related data for you and your family</li>
            <li>Trade union data</li>
            <li>
              Government identification numbers (e.g., Aadhaar, Social Security
              number, driver’s license number)
            </li>
            <li>Date of birth</li>
            <li>Gender, race, and ethnicity</li>
            <li>Health and disability data</li>
          </ul>
        </div>

        <p className="text-2xl leading-9">
          Anyone who sends unsolicited information to the Company by any means,
          e.g., mail, email, message, expressly consents to the storage,
          destruction, processing, or disclosure of the data, as well as any
          other reasonable business-related use by the Company or any government
          agency of the unsolicited data.
        </p>
        <p className="text-2xl leading-9">
          We do monitor employee use of the internet in order to detect access
          to inappropriate websites or other misuse of the Company’s computer
          network. We also use email filters to block spam and computer viruses.
          These filters may from time to time block legitimate email messages.
        </p>
        <p className="text-2xl leading-9">
          The Company will not knowingly collect or use Personal Information in
          any manner not consistent with this Policy, as it may be amended from
          time to time, and applicable laws.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
