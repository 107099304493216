import React from "react";

const ReturnAndRefundPolicy = () => {
  return (
    <div
      className="Lato w-[1200px] mx-auto  flex flex-col gap-12 py-24 justify-center items-center"
      id="return"
    >
      <h1 className="text-[#101828] text-[40px] font-bold">
        Refunds & Cancellation Policy
      </h1>

      <div className="flex flex-col gap-8 h-[1800px]">
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">Returns</h3>
          <p className="text-2xl leading-9">
            We do not accept RTOs once the shipment has been picked up by the
            carrier. In case the shipment is in transit to our hub, and you wish
            to cancel, please write to us at support@goflash.in or call our
            customer care at +917045063838 with details.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">Cancellation</h3>
          <p className="text-2xl leading-9">
            You can cancel the order anytime before it is picked up by any
            carrier. Once you request for cancellation of your order, your
            wallet will be credited with the shipment freight amount which was
            deducted at the time of shipment creation. You can check this
            shipment status on the Flash Platform. We will refund the entire
            amount within 24 - 48 hours.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ReturnAndRefundPolicy;
