import "./App.css";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import ContactForm from "./pages/contact/ContactForm";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import TermOfUse from "./pages/TermOfUse/TermOfUse";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import { Helmet } from "react-helmet";
import ReturnAndRefundPolicy from "./pages/return/ReturnAndRefundPolicy";

function App() {
  return (
    <>
      <Helmet>
        <title>Flash</title>
        <meta
          name="description"
          content="India's #1 Cross Border Logistics Platform. Flash allows exporters to ship
      to 220+ countries with the Cheapest and Fastest Rates."
        />
      </Helmet>

      <Nav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactForm />} />
        <Route path="/terms" element={<TermOfUse />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/return" element={<ReturnAndRefundPolicy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
