import React from "react";

const TermOfUse = () => {
  return (
    <div className="Lato w-[1200px] mx-auto  flex flex-col gap-12 py-24 justify-center items-center" id="terms">
      <h1 className="text-[#101828] text-[40px] font-bold">Terms Of Use</h1>
      <p className="text-[#0F172A] text-2xl leading-9 h-[190px]">
        Thank you for your interest in Flashport Technologies Private Limited
        (the “Company”) for the facilitation of your business requirements –
        Buyer and market access, order fulfillment, shipping, global trade
        compliance, and related financial transactions (“Services”). Please take
        a moment to review the following terms and conditions (the "Terms") that
        govern your use of the Website.
      </p>

      <div className="flex flex-col gap-8 h-[1800px]">
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">1. Acceptance of Terms</h3>
          <p className="text-2xl leading-9">
            By accessing or using the Website, you agree to be bound by these
            Terms, which may be updated from time to time. These terms are a
            legally binding contract between you and the Company. If you do not
            agree with these Terms, you may not register or use any of the
            Services
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">2. Eligibility</h3>
          <p className="text-2xl leading-9">
            By agreeing to these Terms, you hereby represent, warrant and
            covenants that: (i) You have the legal capacity and authority to
            enter into this contract and be bound by the Terms; and (ii) You
            have not been previously suspended in using this website
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">3. Prohibited Use</h3>
          <p className="text-2xl leading-9">
            You are prohibited from using the Website to engage in any unlawful
            activity or to post any content that is illegal, offensive,
            defamatory, or violates the rights of any third party. The Website
            reserves the right to remove any content that violates these Terms
            or is deemed inappropriate in its sole discretion
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">4. Registration</h3>
          <p className="text-2xl leading-9">
            By accessing this website you agree to register and create an
            account for availing the Services. During the registration process,
            you will be required to provide accurate and complete information,
            including a valid email address and password (“User Information”).
            You are responsible for maintaining the confidentiality of your User
            Information and for all activities that occur under your account.
            You agree to notify us immediately if you suspect or become aware of
            any unauthorized use of your account, or breach of your account
            information or password. You also acknowledge that your account and
            User Information is personal to you and agree not to allow any
            unauthorized person to access the Service or any portion of it using
            your User Information. We have the right to disable any username,
            password, or other identifier, whether chosen by you or provided by
            us, at any time in our sole discretion, for any or no reason,
            including if, in our opinion, you have violated any provision of
            these Terms. The Company will not be liable for any loss that you
            may incur as a result of someone else using your User Information,
            either with or without your knowledge
          </p>
        </div>

        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">5. Privacy</h3>
          <p className="text-2xl leading-9">
            We respect your privacy and will protect any personal information
            you provide to us. Please review our Privacy Policy to understand
            our practices. To access our privacy policy, please click on the
            privacy policy on the website.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">6. Intellectual Property</h3>
          <p className="text-2xl leading-9">
            We respect your privacy and will protect any personal information
            you provide to us. Please review our Privacy Policy to understand
            our practices. To access our privacy policy, please click on the
            privacy policy on the website.
          </p>
        </div>
        <div className="text-[#0F172A]">
          <h3 className="text-[32px] font-bold ">7. Disclaimer</h3>
          <p className="text-2xl leading-9">
            All content on this website, including text, graphics, logos,
            images, and software, is the property of the website owner or its
            content suppliers and is protected by law. Nothing in these Terms
            should be construed as assigning or licensing the Company’s
            intellectual property to you.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermOfUse;
