import React from "react";
import { HashLink as Link } from "react-router-hash-link/dist/react-router-hash-link.cjs.production";

const Nav = () => {
  return (
    <nav className="Lato flex justify-between items-center h-[100px] min-w-[1440px] w-full px-[120px] fixed bg-white z-50">
      <Link to="/#hero">
        <div className="flex gap-[9px] items-center">
          <img src="/assets/logo.svg" alt="logo" width={60} height={64} />
          <h3 className="text-2xl font-bold text-[#1D2939]">Flash</h3>
        </div>
      </Link>

      <ul className="flex gap-12 text-[16px] text-[#1D2939]">
        <Link to="/#hero">
          <li>Home</li>
        </Link>
        <Link to="/#how">
          <li>How It Works</li>
        </Link>

        <Link to="/#product">
          <li>Product</li>
        </Link>

        <Link to="/#feature">
          <li>Features</li>
        </Link>

        <Link to="/#solution">
          <li>Why Flash</li>
        </Link>
      </ul>

      <Link to="/contact/#contact">
        <button
          type="button"
          className="w-[172px] py-4 px-6 rounded-lg bg-[#F9F7FD] text-[#4831D4] text-[16px] font-bold"
        >
          Join The Waitlist
        </button>
      </Link>
    </nav>
  );
};

export default Nav;
